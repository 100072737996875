.mediaPlayerWrapper {
    height: 50vh;
    width: 100%;
}

.container {
    height: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
    position: relative;
    background-color: rgb(37, 34, 34);
    /* z-index: 999; */
}
.container-sub {
    display: grid;
    place-items: center;
    overflow: hidden;
    position: relative;
    background-color: rgb(37, 34, 34);
    font-size: 1.5em;
    padding: 2rem;
    /* z-index: 999; */
}

.circle {
    border-radius: 50%;
    width: 20em;
    height: 20em;
    background-color: #FA2742;
    position: absolute;
    opacity: 1;
    animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);
}

.circle-still {
    border-radius: 50%;
    width: 20em;
    height: 20em;
    background-color: #FA2742;
    position: absolute;
    opacity: 1;
}

@keyframes scaleIn {
    from {
        transform: scale(.5, .5);
        opacity: .5;
    }

    to {
        transform: scale(2.5, 2.5);
        opacity: 0;
    }
}

.item {
    z-index: 999;
    padding: 5px;
}

.item img {
    width: 120px;
    border-radius: 50%;
}